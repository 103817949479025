/* App.css */

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: black;
}

.container {
  height: 100vh; /* Total height of all images combined */
  background-color: rgb(0, 0, 0); /* Set container background to yellow */
}


.header2 {
  width: 0%;
}

.section {
  display: flex;
  align-items: center;
  background-color: rgb(0, 0, 0);
  width: 100%;
}


.section img {
  display: block; /* Make sure the image is treated as a block element */
  margin: 0 auto; /* Center the block element (image) horizontally */

}

.header {
  width: 70%;
}
.button {
  background-color: #fffb00; /* Blue */
  border: none;
  color: rgb(0, 0, 0);
  padding: 20px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 26px;
  font-weight: 800;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition-duration: 0.4s;
  margin-right: 30px;
}

.button.active {
  background-color: green; /* Change to desired background color */
  color: white;
}

.supply {
  color: white;
}

.texxts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mint {

  display: flex;
  align-items: center;
  justify-content: center; /* Horizontally center content */
}

.dropdown-container {
  margin: 20px;
}

.dropdown {
  background-color: #fffb00; /* Change background color */
  border: 2px solid rgb(0, 0, 0);
  border-radius: 5px;
  padding: 20px 40px;
  font-size: 26px;
  font-weight: 800;
  width: 150px; /* Change width */
  /* Style the arrow */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  /* Add padding to accommodate the arrow */
  padding-right: 30px;
}

.icontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-evenly;
  margin-top: 30px;
}

.image {
  width: calc(15% - 10px); /* 2 images per row with space in between */
  margin-bottom: 20px;
  background-color: lightgray; /* Placeholder color */
}

.price {
  color: white;
}

.twitter {
  width: 10%;
  background-color: rgb(0, 0, 0);
  cursor: pointer;
}

.price {
  color: white;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;
}

/* For screens with width less than 768px, show images in a single column */
@media (max-width: 767px) {

  .header {
    width: 0%;
  }

  .section {
    margin-top: 10px;
  }

  .header2 {
    width: 90%;
    margin-top: 20px;
  }

  .image {
    width: calc(20% - 10px);
  }

  .twitter {
    width: 10%;
    background-color: rgb(0, 0, 0);
  }
  
  .button {
    background-color: #fffb00; /* Blue */
    border: none;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 800;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition-duration: 0.4s;
    margin-right: 10px;
  }
  .container {
    height: 100vh; /* Total height of all images combined */
    background-color: rgb(0, 0, 0); /* Set container background to yellow */
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontally center */
    width: fit-content; /* Adjust width to fit contents */
  }

  
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: black;
}
.mint {

  background-color: rgb(0, 0, 0);
  width: 100%;

}

.image {
  width: calc(45% - 10px); /* 2 images per row with space in between */
  margin-bottom: 20px;
  background-color: lightgray; /* Placeholder color */
}

.dropdown {
  background-color: #fffb00; /* Change background color */
  border: 2px solid rgb(0, 0, 0);
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 26px;
  font-weight: 800;
  width: 150px; /* Change width */
  /* Style the arrow */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  /* Add padding to accommodate the arrow */
  padding-right: 30px;
}
}
